'use client';

import React, { useState, useEffect, useRef } from "react";
import { Quicksand } from 'next/font/google';
import LandingHero from "@/components/landing-hero";
import NavigationBar from "@/components/NavigationBar";
import dynamic from 'next/dynamic';
import Image from 'next/image';
import '@/app/globals.css';

const quicksand = Quicksand({ subsets: ['latin'], weight: ['300'] });

const SplineComponent = dynamic(() => import('@/components/splinewrapper'), {
  ssr: false,
  loading: () => null,
});

export default function LandingPage() {
  const [isLoading, setIsLoading] = useState(true);
  const [splineLoaded, setSplineLoaded] = useState(false);
  const [mounted, setMounted] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  const isDevComplete = true; 

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (mounted && splineLoaded && contentRef.current) {
      const checkRender = () => {
        if (contentRef.current) {
          const contentBox = contentRef.current.getBoundingClientRect();
          if (contentBox.width > 0 && contentBox.height > 0) {
            // Content has rendered, hide the loading screen
            setIsLoading(false);
          } else {
            // Content not yet rendered, check again in the next frame
            window.requestAnimationFrame(checkRender);
          }
        }
      };

      window.requestAnimationFrame(checkRender);
    }
  }, [splineLoaded, mounted]);

  if (!mounted) {
    return null; // or a simple loading placeholder
  }

  return (
    <>
      {isLoading && (
        <div className="fixed inset-0 bg-[#547190] z-50 flex items-center justify-center">
          <Image
            src="/logo.png"
            alt="Logo"
            style={{ objectFit: 'cover' }}
            width={350}
            height={350}
            className="animate-pulse"
          />
        </div>
      )}
      <div ref={contentRef} className={`${quicksand.className} h-screen overflow-hidden ${isLoading ? 'invisible' : 'fade-in'}`}>
        <div className="relative h-full flex flex-col">
          <div className="absolute inset-0 z-0">
            <SplineComponent 
              scene="https://prod.spline.design/eAgexO9a5px8hiGy/scene.splinecode" 
              onLoad={() => setSplineLoaded(true)}
            />
          </div>
          <div className="relative z-10 flex flex-col h-full">
            <NavigationBar isDevComplete={isDevComplete} />
            <div className="flex-grow flex items-center justify-center">
              <div className="transform translate-y-[-10%]"> {/* Adjust the percentage as needed */}
                <LandingHero isDevComplete={isDevComplete} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}