"use client";

import { useAuth } from "@clerk/nextjs";
import { motion, AnimatePresence } from "framer-motion";

const HERO_TEXTS = {
  title: "Travlogs",
  subtitle: "The Travel Vlog Social Media",
  description: "Powered by conversational answer engine",
  
} as const;

interface LandingHeroProps {
  isDevComplete: boolean;
}

const LandingHero: React.FC<LandingHeroProps> = ({ isDevComplete }) => {
  const { isSignedIn } = useAuth();

  return (
    <div className="text-white font-thin py-12 sm:py-48 text-center space-y-6 sm:space-y-12 max-w-8xl mx-auto px-4 sm:px-24 flex flex-col justify-center min-h-screen">
      <motion.h1
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="text-6xl sm:text-8xl md:text-7xl font-thin leading-none font-quicksand lowercase text-glow"
      >
        <motion.span
          // ... existing motion props ...
          className="text-8xl sm:text-[10rem] md:text-[12rem] block mb-2"
        >
          {HERO_TEXTS.title}
        </motion.span>
        <motion.span
          // ... existing motion props ...
          className="text-2xl sm:text-3xl md:text-5xl block mb-4"
        >
          {HERO_TEXTS.subtitle}
        </motion.span>
        <motion.span
          // ... existing motion props ...
          className="text-lg sm:text-2xl md:text-4xl"
        >
          {HERO_TEXTS.description}
        </motion.span>
      </motion.h1>
      {/* Removed the rotating texts div */}
    </div>
  );
};

export default LandingHero;